import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

const IndexPage = lazy(() => import("../pages/IndexPage"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const ForgotPage = lazy(() => import("../pages/ForgotPage"));
const RegisterPage = lazy(() => import("../pages/RegisterPage"));

const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const ProjectPage = lazy(() => import("../pages/ProjectPage"));
const ProjectDetailPage = lazy(() => import("../pages/ProjectDetailPage"));
const ProjectPackageDetailPage = lazy(() =>
  import("../pages/ProjectPackageDetailPage")
);
const ProductPage = lazy(() => import("../pages/ProductPage"));
const OrderPage = lazy(() => import("../pages/OrderPage"));
const BalancePage = lazy(() => import("../pages/BalancePage"));
const InvoicePage = lazy(() => import("../pages/InvoicePage"));
const InvoicePrintPage = lazy(() => import("../pages/InvoicePrintPage"));
const InvoiceHistoryPage = lazy(() => import("../pages/InvoiceHistoryPage"));
const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function getOrderId(queryString) {
  // Cari posisi dari bagian yang mengandung "order_id="
  const startIndex = queryString.indexOf("order_id=");
  if (startIndex === -1) {
    return "Order ID tidak ditemukan.";
  }

  // Potong string mulai dari "order_id="
  const validQuery = queryString.substring(startIndex);

  // Gunakan URLSearchParams untuk parsing bagian yang valid
  const params = new URLSearchParams("?" + validQuery);

  // Ambil nilai order_id
  const orderId = params.get("order_id");

  // Validasi apakah order_id ditemukan
  return orderId ? orderId : "Order ID tidak ditemukan.";
}

function Router(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <IndexPage />} />

        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute path="/forgot">
          <ForgotPage />
        </PublicRoute>
        <PublicRoute path="/register">
          <RegisterPage />
        </PublicRoute>
        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute path="/project">
          <ProjectPage />
        </PrivateRoute>
        <PrivateRoute path="/project-detail">
          <ProjectDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/package-detail">
          <ProjectPackageDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/product">
          <ProductPage />
        </PrivateRoute>
        <PrivateRoute path="/product-order">
          <OrderPage />
        </PrivateRoute>
        <PrivateRoute path="/balance">
          <BalancePage />
        </PrivateRoute>
        <PrivateRoute path="/invoice">
          <InvoicePage />
        </PrivateRoute>
        <PrivateRoute path="/invoice-history">
          <InvoiceHistoryPage />
        </PrivateRoute>
        <Route
          exact
          path="/transaction/:order_id?"
          render={(props) =>
            props.match.params.order_id ? (
              <InvoicePrintPage order_id={props.match.params.order_id} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <Route
          exact
          path="/transaction-result"
          render={(props) => (
            <InvoicePrintPage
              type="result"
              order_id={getOrderId(props.location.search)}
            />
          )}
        />
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);
