import React from "react";
import { BrowserRouter, Router } from "react-router-dom";
import ClearCache from "react-clear-cache";
import history from "./utils/history";
import Main from "./apps/main";
import BlankCacheComponent from "./components/Blank/BlankCacheComponent";
class App extends React.Component {
  /**
   *
   * @param {Test Drive} props
   */
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    return (
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) =>
          !isLatestVersion ? (
            <BlankCacheComponent
              emptyCacheStorage={emptyCacheStorage}
              isLatestVersion={isLatestVersion}
            />
          ) : (
            <BrowserRouter>
              <Router history={history}>
                <Main
                  isLatestVersion={isLatestVersion}
                  emptyCacheStorage={emptyCacheStorage}
                />
              </Router>
            </BrowserRouter>
          )
        }
      </ClearCache>
    );
  }
}
export default App;
