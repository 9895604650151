/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import CryptoJS from "crypto-js";
export default {
  decrypt(str, encryptKey, ivKey) {
    try {
      const dec = CryptoJS.AES.decrypt(
        str,
        CryptoJS.enc.Utf8.parse(encryptKey),
        {
          iv: CryptoJS.enc.Utf8.parse(ivKey), // parse the IV
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        }
      );
      return dec.toString(CryptoJS.enc.Utf8);
    } catch {
      return false;
    }
  },
  encrypt(str, encryptKey, ivKey) {
    try {
      const enc = CryptoJS.AES.encrypt(
        str?.toString(),
        CryptoJS.enc.Utf8.parse(encryptKey),
        {
          iv: CryptoJS.enc.Utf8.parse(ivKey), // parse the IV
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC,
        }
      );
      return enc.toString();
    } catch {
      return false;
    }
  },
  encryptKey() {
    return this.isAuthenticated()
      ? this.isAuthenticated()?.user?.encryptKey?.substring(23 - 1, 23 + 31)
      : process.env.REACT_APP_ENCRYPT_KEY;
  },
  ivKey() {
    return this.isAuthenticated()
      ? this.isAuthenticated()?.user?.encryptKey?.substring(32 - 1, 32 + 15)
      : process.env.REACT_APP_IV_KEY;
  },
  isAuthenticated() {
    if (localStorage.getItem("session")) {
      const dirtyStorage = JSON.parse(
        this.decrypt(
          localStorage.getItem("session"),
          process.env.REACT_APP_ENCRYPT_KEY,
          process.env.REACT_APP_IV_KEY
        )
      );
      if (dirtyStorage?.token) {
        return dirtyStorage;
      }
    }
    return false;
  },
  truncString(str, max, add) {
    add = add || "...";
    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  },
  formatRupiah(angka) {
    const formatRupiahDesimal = new Intl.NumberFormat("id-ID").format(angka);
    return formatRupiahDesimal;
  },

  formatN(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  },
  // Fungsi untuk menghitung subtotal harga
  calculateSubtotal(data) {
    // Konversi harga utama ke angka
    if (data.subtotal) {
      return data.subtotal;
    }

    const packagePrice = parseInt(data.price, 10);

    // Hitung total harga add-ons
    const addOnsTotal = data.add_on.reduce((total, addOn) => {
      const addOnPrice = parseInt(addOn.price, 10); // Konversi harga add-on ke angka
      return total + addOn.value * addOnPrice;
    }, 0);

    // Hitung subtotal
    return packagePrice + addOnsTotal;
  },
};
