import React, { useEffect, useState } from "react";
import Spin from "antd/es/spin";
import notification from "antd/es/notification";
import ConfigProvider from "antd/es/config-provider";
import CryptoJS from "crypto-js";
import ReactGA from "react-ga4";
import Router from "./router";
import http from "../utils/http";
import helper from "../utils/helper";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
function Main(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "OmniX",
    });
  }, []);

  useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setOpenBackdrop(true);
        const date = new Date().getTime();
        const str = `${process.env.REACT_APP_HEADER_CODE}:${date}`;

        const signed = CryptoJS.HmacSHA256(
          str,
          helper.isAuthenticated()
            ? helper
                .isAuthenticated()
                ?.user?.encryptKey?.substring(23 - 1, 23 + 31)
            : process.env.REACT_APP_ENCRYPT_KEY
        ).toString(CryptoJS.enc.Hex);

        config.headers["x-encrypt"] = signed;
        config.headers["x-time"] = date;

        const isBodyValid = config.data;
        const isParamValid = config.params;

        if (isParamValid) {
          const enc = helper.encrypt(
            JSON.stringify(config.params),
            helper.isAuthenticated()
              ? helper
                  .isAuthenticated()
                  ?.user?.encryptKey?.substring(23 - 1, 23 + 31)
              : process.env.REACT_APP_ENCRYPT_KEY,
            helper.isAuthenticated()
              ? helper
                  .isAuthenticated()
                  ?.user?.encryptKey?.substring(32 - 1, 32 + 15)
              : process.env.REACT_APP_IV_KEY
          );

          config.params = { data: enc };
        }

        if (isBodyValid) {
          const enc = helper.encrypt(
            JSON.stringify(config.data),
            helper.isAuthenticated()
              ? helper
                  .isAuthenticated()
                  ?.user?.encryptKey?.substring(23 - 1, 23 + 31)
              : process.env.REACT_APP_ENCRYPT_KEY,
            helper.isAuthenticated()
              ? helper
                  .isAuthenticated()
                  ?.user?.encryptKey?.substring(32 - 1, 32 + 15)
              : process.env.REACT_APP_IV_KEY
          );
          config.data = { data: enc };
        }
        return config;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "An error occurred in the application, please try again later."
              : "An error occurred in the application, please try again later.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        setOpenBackdrop(false);
        try {
          const decryptedData = helper.decrypt(
            response.data?.data,
            helper.isAuthenticated()
              ? helper
                  .isAuthenticated()
                  ?.user?.encryptKey?.substring(23 - 1, 23 + 31)
              : process.env.REACT_APP_ENCRYPT_KEY,
            helper.isAuthenticated()
              ? helper
                  .isAuthenticated()
                  ?.user?.encryptKey?.substring(32 - 1, 32 + 15)
              : process.env.REACT_APP_IV_KEY
          );
          // Cek apakah JSON valid
          const parsedData = JSON.parse(decryptedData);
          response.data = parsedData; // Mengganti data dengan hasil parse
          if (response?.config?.method !== "get") {
            if (
              response?.data?.message &&
              response?.data?.message !== "success"
            ) {
              notification[response?.data?.status ? "success" : "error"]({
                message: Array.isArray(response.data.message)
                  ? response.data.message.join(",")
                  : response.data.message,
                placement: "bottomRight",
                duration: 5,
              });
            }
          }
        } catch (e) {
          // Menangani exception jika JSON tidak valid
          console.error("Invalid JSON response:", e);
          throw new Error("Invalid JSON response");
        }

        return response;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "An error occurred in the application, please try again later."
              : "An error occurred in the application, please try again later.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );

    if (!props.isLatestVersion) {
      props.emptyCacheStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#065AD8",
          colorLink: "#065AD8",
          colorLinkHover: "#065AD8",
        },
      }}
    >
      <Spin spinning={openBackdrop} tip="Please wait..." className="h-full">
        <Router />
      </Spin>
    </ConfigProvider>
  );
}

export default Main;
